import React, { useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { setConfiguration } from 'react-grid-system';
import shortid from 'shortid';

import LayoutHome from '../components/Layouts/LayoutHome';
import ContentModules from '../components/ContentModules/ContentModules';

// Define the breakpoints for the grid system
setConfiguration({ containerWidths: [540, 960, 1140, 1600] });

const IndexPage = () => {
  // Scroll to the top of the page on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
            }
          }

          wordpressPage(slug: { eq: "homepage" }, status: { eq: "publish" }) {
            yoast {
              title
              metadesc
              linkdex
              metakeywords
              meta_robots_noindex
              meta_robots_nofollow
              meta_robots_adv
              canonical
              redirect
              opengraph_title
              opengraph_description
              opengraph_image
              twitter_title
              twitter_description
            }

            link
            title

            acf {
              opportunities_bar_active
              opportunities_bar_title
              opportunities_bar_jobs {
                location
                title
                url
                logo {
                  url {
                    source_url
                  }
                }
              }
              home_hero_title
              home_hero_content
              home_hero_bg {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1440, fit: FILL, quality: 75, toFormat: JPG) {
                      tracedSVG
                      sizes
                      src
                      srcSet
                      aspectRatio
                    }
                  }
                }
              }
              home_hero_btn_label
              home_hero_btn_page {
                post_name
              }

              home_hero_cta {
                panel_one_title
                panel_one_content
                panel_one_button_one_label
                panel_one_button_one_page {
                  post_name
                }
                panel_one_button_two_label
                panel_one_button_two_page {
                  post_name
                }

                panel_two_title
                panel_two_content
                panel_two_button_one_label
                panel_two_button_one_page {
                  post_name
                }
                panel_two_button_two_label
                panel_two_button_two_page {
                  post_name
                }
              }

              home_hero_intro_title
              home_hero_intro_subtitle
              home_hero_intro_btn_label
              home_hero_intro_btn_page {
                post_name
              }

              content_modules_page {
                __typename
                ... on WordPressAcf_register {
                  title
                  subtitle
                }
                ... on WordPressAcf_quote {
                  quote
                  bg_image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 2560) {
                          tracedSVG
                          sizes
                          src
                          srcSet
                          aspectRatio
                        }
                      }
                    }
                  }
                }
                ... on WordPressAcf_content_image {
                  title
                  subtitle
                  layout
                  content
                  button
                  button_label
                  button_label_2
                  button_label_3
                  button_url_type
                  button_url_type_2
                  button_url_type_3
                  button_url_page
                  button_url_page_2
                  button_url_page_3
                  button_url_type_2
                  button_url_type_3
                  button_url_external
                  button_url_external_2
                  button_url_external_3
                  image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 2560) {
                          tracedSVG
                          sizes
                          src
                          srcSet
                          aspectRatio
                        }
                      }
                    }
                  }
                }
                ... on WordPressAcf_downloads {
                  title
                  subtitle
                  downloads {
                    title
                    description
                    file {
                      source_url
                    }
                  }
                }
                ... on WordPressAcf_fourcol {
                  title
                  subtitle
                  columns {
                    title
                    content
                  }
                }
                ... on WordPressAcf_text {
                  content
                }
                ... on WordPressAcf_team_members {
                  title
                  subtitle
                  members {
                    profile_link_type
                    profile_url
                    profile_url_external
                    job_title
                    member {
                      post_title
                      acf {
                        image {
                          localFile {
                            childImageSharp {
                              fluid(maxWidth: 600) {
                                tracedSVG
                                sizes
                                src
                                srcSet
                                aspectRatio
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          wordpressAcfOptions {
            options {
              options_rescue {
                panel_one_title
                panel_one_label
                panel_one_page {
                  post_name
                }
                panel_two_title
                panel_two_label
                panel_two_page {
                  post_name
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <LayoutHome
            data={data.wordpressPage}
            optionsData={data.wordpressAcfOptions}
            siteMeta={data.site}
          >
            {data.wordpressPage.acf.content_modules_page.map((module) => {
              return <ContentModules key={shortid.generate()} module={module} />;
            })}
          </LayoutHome>
        );
      }}
    />
  );
};

export default IndexPage;
