import React from 'react';
import { Container, Row, Col, Visible } from 'react-grid-system';
import { css } from 'emotion';
import { Link } from 'gatsby';

import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';
import { breakup } from '../../config/breakpoints';

// Components
// -----
import Button from '../Common/ButtonLight';
import Content from '../Common/Content';
import OpportuntiesBar from './OpportunitiesBar';
import Title from '../Common/Title';

// Component
// -----
const HomeHero = ({
  title,
  content,
  bgImage,
  bgColor,
  buttonLabel,
  url,
  opportunitiesTitle,
  opportunities,
  opportunitiesActive,
}) => {
  const cssWrapper = css`
    position: relative;
    padding-top: 15rem;
    padding-bottom: 15rem;
    display: flex;
    align-items: center;

    background-color: ${bgColor};

    &:before {
      content: '';
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      background-image: url('${bgImage}');
      background-size: cover;
      background-position: center center;
      opacity: .15;
    }

    ${breakup.medium`
      height: 90vh;
      padding-top: 25%;
    `}

    ${breakup.xlarge`min-height: 85rem;`}
  `;

  const cssBottom = css`
    ${breakup.large`
      position: absolute !important;
      width: 100%;
      left: 50%;
      top: 25%;
      transform: translateX(-50%);
    `}
  `;

  return (
    <section className={cssWrapper}>
      <Container fluid className={cssBottom}>
        <Row>
          <Col sm={12} md={8} push={{ md: 1 }} lg={6} xl={5}>
            <Title size={1} color={themeVars.root.light}>
              {title}
            </Title>
            <Content color={themeVars.root.light} content={content} />
            <Link to={`/${url}/`}>
              <Button style={{ marginTop: '3rem' }} type="text">
                {buttonLabel}
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>

      <Visible xl lg>
        <OpportuntiesBar
          active={opportunitiesActive}
          title={opportunitiesTitle}
          opportunities={opportunities}
        />
      </Visible>
    </section>
  );
};

// Props
// -----
HomeHero.defaultProps = {
  content: null,
  bgColor: themeVars.root.secondary,
};

HomeHero.propTypes = {
  opportunitiesActive: PropTypes.bool.isRequired,
  /** The title of the opportunities bat */
  opportunitiesTitle: PropTypes.string.isRequired,

  /** An array of jobs for the opportunities bat */
  opportunities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

  /** The background colour of the Hero */
  bgColor: PropTypes.string,

  /** The backgroud image of the Hero */
  bgImage: PropTypes.string.isRequired,

  /** The title of the Hero */
  title: PropTypes.string.isRequired,

  /** The subtitle of the Hero */
  content: PropTypes.string,

  /** Text for the Button Label */
  buttonLabel: PropTypes.string.isRequired,

  /** URL for the Button */
  url: PropTypes.string.isRequired,
};

export default HomeHero;
