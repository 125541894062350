import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { themeVars } from '../../config/styles';
import { breakup } from '../../config/breakpoints';

// Components
// -----
import Title from '../Common/Title';
import Content from '../Common/Content';
import Button from '../Common/ButtonLight';
import ButtonDark from '../Common/ButtonDark';

// Component
// -----
const SplitCta = ({
  firstPanelTitle,
  firstPanelContent,
  firstPanelColor,
  hasButtonOne,
  hasButtonTwo,
  hasButtonThree,
  hasButtonFour,
  hasButtonIntro,
  firstPanelBtnOneUrl,
  firstPanelBtnOneLabel,
  firstPanelBtnTwoUrl,
  firstPanelBtnTwoLabel,
  secondPanelTitle,
  secondPanelContent,
  secondPanelColor,
  secondPanelBtnOneUrl,
  secondPanelBtnOneLabel,
  secondPanelBtnTwoUrl,
  secondPanelBtnTwoLabel,
  introTitle,
  introContent,
  introBtnUrl,
  introBtnLabel,
  introImageUrl,
}) => {
  const cssWrapper = css`
    position: relative;
    z-index: 1;
  `;

  const cssFirstPanel = css`
    position: relative;
    background-color: ${firstPanelColor};
    padding: 8rem 0;

    ${breakup.large`
      padding: 8rem 0 20rem;
    `}
  `;

  const cssSecondPanel = css`
    position: relative;
    height: 100%;
    background-color: ${secondPanelColor};
    padding: 8rem 0;

    ${breakup.large`
      padding: 8rem 0 20rem;
    `}
  `;

  const cssIntro = css`
    position: relative;
    z-index: 3;

    ${breakup.large`
      margin-top: -10rem;
    `}

    &:before {
      ${breakup.medium`
        content: '';
        position: absolute;
        top: 0; right: 0;
        height: 50%;
        width: 16.666%;
        background: ${secondPanelColor};
      `}
    }
  `;

  const cssIntroInner = css`
    background-color: ${themeVars.root.light};
    padding: 8rem 0;
  `;

  const introImage = css`
    margin-top: 4rem;

    ${breakup.medium`margin-top: 0;`}
  `;

  return (
    <>
      <section className={cssWrapper}>
        <Container fluid style={{ padding: '0' }}>
          <Row nogutter>
            <Col xs={12} md={6}>
              <div className={cssFirstPanel}>
                <Row>
                  <Col xs={10} md={9} push={{ xs: 1, md: 2 }} lg={8}>
                    <Title size={2} color={themeVars.root.light}>
                      {firstPanelTitle}
                    </Title>
                    <Content color={themeVars.root.light} content={firstPanelContent} />
                    {hasButtonOne && (
                      <Link to={`/${firstPanelBtnOneUrl}/`}>
                        <Button style={{ marginTop: '4rem', marginRight: '2rem' }} type="text">
                          {firstPanelBtnOneLabel}
                        </Button>
                      </Link>
                    )}

                    {hasButtonTwo && (
                      <Link to={`/${firstPanelBtnTwoUrl}/`}>
                        <Button style={{ marginTop: '4rem' }} type="text">
                          {firstPanelBtnTwoLabel}
                        </Button>
                      </Link>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className={cssSecondPanel}>
                <Row>
                  <Col xs={10} md={9} push={{ xs: 1, md: 2 }} lg={8}>
                    <Title size={2} color={themeVars.root.light}>
                      {secondPanelTitle}
                    </Title>
                    <Content color={themeVars.root.light} content={secondPanelContent} />
                    {hasButtonThree && (
                      <Link to={`/${secondPanelBtnOneUrl}/`}>
                        <Button style={{ marginTop: '4rem', marginRight: '2rem' }} type="text">
                          {secondPanelBtnOneLabel}
                        </Button>
                      </Link>
                    )}
                    {hasButtonFour && (
                      <Link to={`/${secondPanelBtnTwoUrl}/`}>
                        <Button style={{ marginTop: '4rem' }} type="text">
                          {secondPanelBtnTwoLabel}
                        </Button>
                      </Link>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={cssIntro}>
        <Container fluid style={{ padding: '0' }}>
          <Row nogutter>
            <Col xs={12} md={10}>
              <div className={cssIntroInner}>
                <Container fluid>
                  <Row>
                    <Col xs={10} push={{ xs: 1 }} md={6} lg={5}>
                      <Title size={3} color={themeVars.root.dark}>
                        {introTitle}
                      </Title>
                      <Content color={themeVars.root.dark} content={introContent} />
                      {hasButtonIntro && (
                        <Link to={`/${introBtnUrl}/`}>
                          <ButtonDark style={{ marginTop: '4rem' }} type="text">
                            {introBtnLabel}
                          </ButtonDark>
                        </Link>
                      )}
                    </Col>
                    <Col xs={10} push={{ xs: 1 }} md={5} lg={6} align="center">
                      <img className={introImage} src={introImageUrl} alt="" />
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

// Props
// -----
SplitCta.defaultProps = {
  hasButtonOne: false,
  hasButtonTwo: false,
  hasButtonThree: false,
  hasButtonFour: false,
  hasButtonIntro: false,
};

SplitCta.propTypes = {
  /** Should the button be displayed */
  hasButtonOne: PropTypes.bool,
  hasButtonTwo: PropTypes.bool,
  hasButtonThree: PropTypes.bool,
  hasButtonFour: PropTypes.bool,
  hasButtonIntro: PropTypes.bool,

  /** First Panel Title */
  firstPanelTitle: PropTypes.string.isRequired,

  /** First Panel Content */
  firstPanelContent: PropTypes.string.isRequired,

  /** Panel background color */
  firstPanelColor: PropTypes.string.isRequired,

  /** First Panel Button One Label */
  firstPanelBtnOneLabel: PropTypes.string.isRequired,

  /** First Panel Button One URL */
  firstPanelBtnOneUrl: PropTypes.string.isRequired,

  /** First Panel Button Two Label */
  firstPanelBtnTwoLabel: PropTypes.string.isRequired,

  /** First Panel Button Two URL */
  firstPanelBtnTwoUrl: PropTypes.string.isRequired,

  /** Second Panel Title */
  secondPanelTitle: PropTypes.string.isRequired,

  /** Second Panel Content */
  secondPanelContent: PropTypes.string.isRequired,

  /** Panel background color */
  secondPanelColor: PropTypes.string.isRequired,

  /** Second Panel Button One Label */
  secondPanelBtnOneLabel: PropTypes.string.isRequired,

  /** Second Panel Button One URL */
  secondPanelBtnOneUrl: PropTypes.string.isRequired,

  /** Second Panel Button Two Label */
  secondPanelBtnTwoLabel: PropTypes.string.isRequired,

  /** Second Panel Button Two URL */
  secondPanelBtnTwoUrl: PropTypes.string.isRequired,

  /** Introduction Title */
  introTitle: PropTypes.string.isRequired,

  /** Introduction Content */
  introContent: PropTypes.string.isRequired,

  /** Introduction Button Label */
  introBtnLabel: PropTypes.string.isRequired,

  /** Introduction Button URL */
  introBtnUrl: PropTypes.string.isRequired,

  /** Introduction Image */
  introImageUrl: PropTypes.string.isRequired,
};

export default SplitCta;
