import React from 'react';
import { css } from 'emotion';
import { Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';

// Vars
import { themeVars } from '../../config/styles';

import { breakup } from '../../config/breakpoints';

const OpportuntiesBar = (props) => {
  const { isMobile, title, opportunities, active } = props;

  const cssWrapper = css`
    position: ${!isMobile && 'absolute'};
    bottom: 0;
    width: 100%;
    background-color: ${isMobile ? themeVars.root.primary : 'rgba(255, 255, 255, 0.2)'};
    padding: ${isMobile && '45px'};
  `;

  const cssJob = css`
    font-size: 1.5rem;
    font-weight: 300;

    ${breakup.xlarge`font-size: 1.8rem;`}

    &:hover {
      color: #293037;
    }
  `;

  const cssTitle = css`
    color: #fff;
    font-weight: 300;
    font-size: 3.2rem;
    line-height: 1.2;
    margin: ${isMobile ? '0 0 20px' : '0 0 5px'};
  `;

  const cssLocation = css`
    font-size: 1.8rem;
    font-weight: 800;
  `;

  const cssItem = css`
    height: 100%;

    a {
      color: #fff;
      display: block;
      padding: 25px 15px;
      transition: 150ms ease-in-out all;
      background-color: transparent;
      height: 100%;

      :not(:hover) {
        * {
          fill: #fff;
          transition: 0ms ease-in-out all;
        }
      }

      &:hover {
        background-color: #fff;
        color: #293037;

        * {
          fill: #293037 !important;
        }
      }

      .logo {
        margin-bottom: 10px;

        svg {
          max-width: 100%;
          height: 45px;
          margin-bottom: 15px;
          width: auto;
        }
      }
    }
  `;

  const cssLink = css`
    font-size: 1.6rem;
    font-weight: bold;
    margin: 0;
  `;

  const cssNoPadding = css`
    padding-left: 0 !important;
    padding-right: 0 !important;
  `;

  return (
    opportunities &&
    active && (
      <div className={cssWrapper}>
        <Row>
          <Col sm={12} md={12} xl={10} lg={10} push={{ xl: 1, lg: 1 }}>
            <Row align="center">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <h2 className={cssTitle} dangerouslySetInnerHTML={{ __html: title }} />
              </Col>

              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Row>
                  {opportunities.map((item) => (
                    <Col xl={4} lg={4} md={6} xs={12} className={cssNoPadding}>
                      <div className={cssItem}>
                        <a aria-label={item.location} href={item.url}>
                          <ReactSVG className="logo" src={item.logo.url.source_url} />
                          <p className={cssLocation}>{item.location}</p>
                          <p className={cssJob}>{item.title}</p>

                          <p className={cssLink}>Apply</p>
                        </a>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  );
};

OpportuntiesBar.defaultProps = {
  isMobile: false,
};

OpportuntiesBar.propTypes = {
  active: PropTypes.bool.isRequired,
  /** Is the component a mobile version rendered below the hero */
  isMobile: PropTypes.bool,

  /** The title of the opportunities bat */
  title: PropTypes.string.isRequired,

  /** An array of jobs for the opportunities bat */
  opportunities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default OpportuntiesBar;
