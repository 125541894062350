import React from 'react';
import { Helmet } from 'react-helmet';
import { Hidden } from 'react-grid-system';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';

import introLogo from '../../assets/images/logo-mark.svg';
import logo from '../../assets/images/logo-white.svg';
import defaultOGimage from '../../assets/images/pratap-social.jpg';

// Config
import { themeVars } from '../../config/styles';

// Components
import CtaItem from '../Footer/CtaItem';
import CtaList from '../Footer/CtaList';
import Footer from '../Footer/Footer';
import Header from '../Common/Header';
import HomeHero from '../Home/HomeHero';
import OpportuntiesBar from '../Home/OpportunitiesBar';
import SplitCta from '../Home/SplitCta';

const LayoutHome = ({ children, data, optionsData, siteMeta }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title>{data.yoast.title || siteMeta.title}</title>
        <meta
          name="description"
          content={
            data.yoast.metadesc.trim() !== ''
              ? data.yoast.metadesc
              : siteMeta.siteMetadata.description
          }
        />
        <meta property="og:title" content={data.yoast.opengraph_title} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={data.yoast.opengraph_description} />
        <meta property="og:url" content={data.link} />
        <meta
          property="og:image"
          content={
            data.yoast.opengraph_image
              ? `http://cms.pratappartnership.co.uk/wp-content/uploads/${data.yoast.opengraph_image}`
              : `http://pratappartnership.co.uk/${defaultOGimage}`
          }
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={data.yoast.twitter_description} />
        <meta name="twitter:title" content={data.yoast.twitter_title} />
        <meta
          name="twitter:image"
          content={
            data.yoast.opengraph_image
              ? `http://cms.pratappartnership.co.uk/wp-content/uploads/${data.yoast.opengraph_image}`
              : `http://pratappartnership.co.uk/${defaultOGimage}`
          }
        />
      </Helmet>

      <CssBaseline />

      <Header logo={logo} isHome />

      <HomeHero
        bgColor={themeVars.root.primary}
        bgImage={data.acf.home_hero_bg.localFile.childImageSharp.fluid.src}
        buttonLabel={data.acf.home_hero_btn_label}
        content={data.acf.home_hero_content}
        title={data.acf.home_hero_title}
        url={data.acf.home_hero_btn_page.post_name}
        opportunitiesTitle={data.acf.opportunities_bar_title}
        opportunities={data.acf.opportunities_bar_jobs}
        opportunitiesActive={data.acf.opportunities_bar_active}
      />

      <Hidden xl lg>
        <OpportuntiesBar
          title={data.acf.opportunities_bar_title}
          opportunities={data.acf.opportunities_bar_jobs}
          isMobile
          active={data.acf.opportunities_bar_active}
        />
      </Hidden>

      <SplitCta
        firstPanelBtnOneLabel={data.acf.home_hero_cta.panel_one_button_one_label}
        firstPanelBtnOneUrl={data.acf.home_hero_cta.panel_one_button_one_page.post_name}
        firstPanelBtnTwoLabel={data.acf.home_hero_cta.panel_two_button_two_label}
        firstPanelBtnTwoUrl={data.acf.home_hero_cta.panel_two_button_two_page.post_name}
        firstPanelColor={themeVars.root.secondary}
        firstPanelContent={data.acf.home_hero_cta.panel_one_content}
        firstPanelTitle={data.acf.home_hero_cta.panel_one_title}
        hasButtonFour
        hasButtonIntro
        hasButtonOne
        hasButtonThree
        hasButtonTwo
        introBtnLabel={data.acf.home_hero_intro_btn_label}
        introBtnUrl={data.acf.home_hero_intro_btn_page.post_name}
        introContent={data.acf.home_hero_intro_subtitle}
        introImageUrl={introLogo}
        introTitle={data.acf.home_hero_intro_title}
        secondPanelBtnOneLabel={data.acf.home_hero_cta.panel_two_button_one_label}
        secondPanelBtnOneUrl={data.acf.home_hero_cta.panel_two_button_one_page.post_name}
        secondPanelBtnTwoLabel={data.acf.home_hero_cta.panel_two_button_two_label}
        secondPanelBtnTwoUrl={data.acf.home_hero_cta.panel_two_button_two_page.post_name}
        secondPanelColor={themeVars.root.darkAlt}
        secondPanelContent={data.acf.home_hero_cta.panel_two_content}
        secondPanelTitle={data.acf.home_hero_cta.panel_two_title}
      />

      <main>{children}</main>

      <CtaList>
        <CtaItem
          bgColor={themeVars.root.secondary}
          label={optionsData.options.options_rescue.panel_one_label}
          title={optionsData.options.options_rescue.panel_one_title}
          to={optionsData.options.options_rescue.panel_one_page.post_name}
        />
        <CtaItem
          bgColor={themeVars.root.secondaryDark}
          label={optionsData.options.options_rescue.panel_two_label}
          title={optionsData.options.options_rescue.panel_two_title}
          to={optionsData.options.options_rescue.panel_two_page.post_name}
        />
      </CtaList>

      <Footer />
    </>
  );
};

LayoutHome.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape).isRequired,
  data: PropTypes.shape({}).isRequired,
  optionsData: PropTypes.shape({}).isRequired,
};

export default LayoutHome;
